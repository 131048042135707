'use client';

import React, { useEffect, useState } from 'react';
import { LookbookDrawerProps } from './type';
import LookbookDrawerHeader from './LookbookDrawerHeader';
import LookbookDrawerContent from './LookbookDrawerContent';
import fetchAllProductHandles from '../../lib/shopify/methods/fetch-all-product-handles';
import { Drawer, DrawerPanel, DrawerTrigger } from '../ui/drawer/Drawer';
import useUI from '../ui/state';
import useDrawerDirection from '../../lib/hooks/use-drawer-direction';
import { Product } from 'shopify-storefront-api-typings';
import { useParams } from 'next/navigation';
import { getShopifyClientSettings } from '@app/lib/shopify/get-client-settings';
import { Language } from '@app/lib/store-context/Context';
import { v4 as uuid } from 'uuid';

const LookbookDrawer = ({ look, heading, children, handles }: LookbookDrawerProps) => {
    const [drawerID] = useState(uuid());
    const [products, setProducts] = useState<Product[] | null>(null);
    const { openUIKey, toggleUI } = useUI();
    const drawerDirection = useDrawerDirection();
    const { lang } = useParams();
    const clientSettings = getShopifyClientSettings(lang as Language);

    const isDrawerOpen = openUIKey === drawerID;

    useEffect(() => {
        if (!handles) return;
        const filteredProductHandles: string[] = handles.filter(
            (handle) => handle !== undefined && handle
        ) as string[];

        if (filteredProductHandles) {
            const fetchTheProduct = async () => {
                const fetchedProducts = await fetchAllProductHandles(
                    clientSettings,
                    filteredProductHandles
                );

                if (!fetchedProducts) return;
                setProducts(fetchedProducts);
            };
            fetchTheProduct().then(
                (error) => `Error fetching products: ${error}`
            );
        }
    }, [handles]);

    if (!products) return null;

    const toggleDrawer = () => {
        toggleUI(isDrawerOpen ? null : drawerID);
    };

    // To filter out null items and get the actual product length
    const filteredProducts = products.filter((item: any) => item);

    return (
        <Drawer open={isDrawerOpen} onOpenChange={toggleDrawer}>
            <DrawerTrigger>{children}</DrawerTrigger>
            <DrawerPanel inFrom={drawerDirection} variant="lookbook">
                <LookbookDrawerHeader
                    title={look?.heading || heading}
                    productCount={filteredProducts.length}
                />
                <LookbookDrawerContent
                    products={products}
                    lookbookUIKey={look?.heading || heading}
                />
            </DrawerPanel>
        </Drawer>
    );
};

export default LookbookDrawer;
