import fetchProduct from '../../../lib/shopify/methods/fetch-product';
import { Product } from 'shopify-storefront-api-typings';
import { ShopifyClientContext } from '@app/lib/shopify/client';

function notNull<T>(val: T | null): val is T {
    return val !== null;
}
export default async function fetchAllProductHandles(
    clientSettings: ShopifyClientContext,
    handles: string[]
): Promise<Product[]> {
    if (!Array.isArray(handles)) {
        console.error('Invalid product handles', handles);
        return [];
    }

    const promiseResponses = await Promise.all(
        handles.map((handle) => fetchProduct(clientSettings, handle))
    );
    return promiseResponses.filter(notNull);
}
