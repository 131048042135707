import { useState, useEffect, useRef, useCallback } from 'react';

interface HorizontalScrollObserverReturn {
    scrollContainerRef: React.RefObject<HTMLDivElement>;
    hasReachedEnd: boolean;
    hasOverflowRight: boolean;
    hasOverflowLeft: boolean;
}

const useHorizontalScrollObserver = (): HorizontalScrollObserverReturn => {
    const [hasReachedEnd, setHasReachedEnd] = useState<boolean>(false);
    const [hasOverflowRight, setHasOverflowRight] = useState<boolean>(false);
    const [hasOverflowLeft, setHasOverflowLeft] = useState<boolean>(false);

    const scrollContainerRef = useRef<HTMLDivElement>(null);
    const frameId = useRef<number | null>(null);

    const updateScrollStates = useCallback(() => {
        const scrollContainer = scrollContainerRef.current;
        if (scrollContainer) {
            const isAtEnd =
                scrollContainer.scrollLeft + scrollContainer.clientWidth >=
                scrollContainer.scrollWidth - 2;
            const overflowRight =
                scrollContainer.scrollWidth > scrollContainer.clientWidth;
            const overflowLeft = scrollContainer.scrollLeft > 0;

            setHasOverflowRight(overflowRight);
            setHasOverflowLeft(overflowLeft);
            setHasReachedEnd(isAtEnd);
        }
    }, []);

    const handleScroll = useCallback(() => {
        if (frameId.current === null) {
            frameId.current = window.requestAnimationFrame(() => {
                updateScrollStates();
                frameId.current = null;
            });
        }
    }, [updateScrollStates]);

    useEffect(() => {
        const scrollContainer = scrollContainerRef.current;
        if (!scrollContainer) {
            return;
        }

        updateScrollStates();

        scrollContainer.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleScroll);

        return () => {
            if (frameId.current !== null) {
                window.cancelAnimationFrame(frameId.current);
            }
            scrollContainer.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleScroll);
        };
    }, [handleScroll, updateScrollStates]);
    return {
        scrollContainerRef,
        hasReachedEnd,
        hasOverflowRight,
        hasOverflowLeft,
    };
};

export default useHorizontalScrollObserver;
