'use client';

import React, { useState, useEffect } from 'react';
import { SwiperSlide } from 'swiper/react';
import { Product } from 'shopify-storefront-api-typings';
import ScrollBarSwiper from '@app/components/scrollbar-swiper';
import { getShortDescription } from '@app/[lang]/products/utilities';
import truncateDescription from '@app/lib/helpers/truncate-description';
import { DrawerFooter } from '@app/components/ui/drawer/Drawer';
import ProductBuy from '@app/[lang]/products/product-buy/product-buy';
import localisePath from '@app/lib/helpers/localise-path';
import { Button, Left, Right, slugify, StaticImage } from 'mmds';
import { useParams } from 'next/navigation';
import proxyImageUrlIfEnabled from '@app/lib/images/proxy-image-url-if-enabled';
import cn from 'clsx';
import s from './Lookbook.module.css';
import { Language } from '@app/lib/store-context/Context';
import { useSelectedProductStore } from './state';
import { createGalleryItems } from '@app/[lang]/products/utilities/create-gallery-items';

interface LookBookDrawerContent {
    products: Product[];
    lookbookUIKey?: string;
}

const LookBookDrawerContent = ({
    products,
    lookbookUIKey,
}: LookBookDrawerContent) => {
    const { lang } = useParams();
    const [selectedProduct, setSelectedProduct] = useState<Product>(
        products[0]
    );
    const { setSelectedProduct: setStoreSelectedProduct } =
        useSelectedProductStore();

    useEffect(() => {
        setStoreSelectedProduct(selectedProduct);
    }, [selectedProduct, setStoreSelectedProduct]);

    const getThumbnail = (product: Product) => {
        const filterThumbnailImages = product?.images?.edges?.filter((node) => {
            return node?.node?.transformedSrc.includes('_LP_FLATLAY');
        });

        return filterThumbnailImages?.[0];
    };

    const title = selectedProduct?.title;
    const variantColor = selectedProduct?.options[0].values[0];
    const productLink = selectedProduct?.handle;

    // SIZE
    const sizes = selectedProduct.variants.edges.map((variant: any) => ({
        size: variant.node.selectedOptions.find(
            (option) => option.name === 'Size'
        )?.value,
        availability: variant.node.quantityAvailable,
    }));

    // PRICE
    const defaultVariant: any = selectedProduct.variants.edges[0].node;
    const price = defaultVariant.priceV2.amount;
    const compareAtPrice = defaultVariant.compareAtPriceV2
        ? defaultVariant.compareAtPriceV2.amount
        : null;
    const currencyCode = defaultVariant.priceV2.currencyCode;

    const images = selectedProduct.images.edges.map((edge) => {
        const { transformedSrc, altText } = edge.node;
        return {
            src: transformedSrc,
            alt: altText || 'Product Image',
        };
    });

    const galleryImages = createGalleryItems(images, undefined);

    const shortDescription = getShortDescription(selectedProduct)?.replace(
        '</br>',
        ''
    );
    const longDescription = selectedProduct?.description;

    return (
        <>
            <div className="lookbookDrawer">
                <div className={s.thumbnailContainer}>
                    {products?.map((product) => {
                        let thumbnail = getThumbnail(product);
                        const selectedThumbnailClass = cn({
                            [s.selected]:
                                selectedProduct?.title === product?.title,
                        });
                        return (
                            thumbnail?.node?.transformedSrc && (
                                <div
                                    onClick={() => setSelectedProduct(product)}
                                    className={cn(
                                        s.thumbnail,
                                        selectedThumbnailClass
                                    )}
                                    key={product?.title}
                                >
                                    <StaticImage
                                        desktopImage={
                                            thumbnail?.node?.transformedSrc
                                        }
                                        mobileImage={
                                            thumbnail?.node?.transformedSrc
                                        }
                                        altDescription={selectedProduct?.title}
                                        desktopWidth="64px"
                                        desktopHeight="84px"
                                        mobileHeight="64px"
                                        mobileWidth="48px"
                                        urlTransformer={proxyImageUrlIfEnabled}
                                    />

                                    {selectedProduct?.title ===
                                        product?.title && (
                                        <div className={s.thumbnailSelected} />
                                    )}
                                </div>
                            )
                        );
                    })}
                </div>
                <div className={s.contextContainer}>
                    <div className={s.productTitle}>
                        <h3 className="mmds-component-one-detail">{title}</h3>
                        <span className="mmds-component-one-detail">
                            {variantColor}
                        </span>
                    </div>
                    <div className={s.iconContainer}>
                        <div className="swiper-button swiper-icon-prev-9">
                            <Left />
                        </div>
                        <div className="swiper-button swiper-icon-next-9">
                            <Right />
                        </div>
                    </div>
                </div>
                <ScrollBarSwiper
                    className="swiper9"
                    slidePerViewInDesktop={3.11}
                    slidePerViewInMobile={2.45}
                    spaceBetweenDesktop={1}
                    spaceBetweenMobile={1}
                    swiperIconNextClassName="swiper-icon-next-9"
                    swiperIconPrevClassName="swiper-icon-prev-9"
                    hasScrollbar={false}
                >
                    {galleryImages?.map((item, index) => {
                        const identifier = `swiper_lookbook_${slugify(
                            selectedProduct?.title
                        )}`;
                        return (
                            <SwiperSlide key={index} data-testid={identifier}>
                                {item.productImage && (
                                    <StaticImage
                                        desktopImage={
                                            item.productImage[0].desktopImage
                                        }
                                        mobileImage={
                                            item.productImage[0].mobileImage
                                        }
                                        fullWidth={true}
                                        altDescription={
                                            item.productImage[0].altText ||
                                            selectedProduct?.title
                                        }
                                        adjustAspectRatio
                                    />
                                )}
                            </SwiperSlide>
                        );
                    })}
                </ScrollBarSwiper>
                <div className={cn(s.description, 'mmds-copy-one')}>
                    <p>
                        {shortDescription
                            ? truncateDescription(shortDescription)
                            : truncateDescription(longDescription)}
                    </p>
                    <div>
                        <Button
                            label="View Product"
                            variant="text"
                            textVariantPadding={false}
                            hrefLink={localisePath(
                                `/products/${productLink}`,
                                lang as Language
                            )}
                            lang={lang as Language}
                        />
                    </div>
                </div>
            </div>
            <DrawerFooter>
                <ProductBuy
                    price={price}
                    compareAtPrice={compareAtPrice}
                    sizes={sizes}
                    currencyCode={currencyCode}
                    lookbookUIKey={lookbookUIKey}
                    inDrawer
                />
            </DrawerFooter>
        </>
    );
};
export default LookBookDrawerContent;
