'use client';

import { useEffect } from 'react';
import { useBuyContext } from '@app/[lang]/products/context';
import { VariantLifecycleStatus } from '@app/[lang]/products/utilities/get-product-lifecycle-status';
import useUI from '@app/components/ui/state';
import getFeatureFlag from '@app/lib/features/get-feature-flag';
import useHorizontalScrollObserver from '@app/lib/hooks/use-horizontal-scroll-observer';
import useLoopReturns from '@app/lib/loop-returns/state';
import cn from 'clsx';
import {
    BookmarkDisabled,
    BookmarkFilled,
    BookmarkUnfilled,
    CartButton,
    CartButtonProps,
    SizeSelector,
    SizeState,
} from 'mmds';
import { useParams } from 'next/navigation';
import useWishlist from '@app/lib/wishlist/state';
import s from './product-buy.module.css';

export interface ProductBuyCallbacks {
    selectSize: (size: string) => void;
    addToCart: () => void;
}

interface BuyProps {
    price: number;
    compareAtPrice: number;
    sizes: any; // TODO
    currencyCode: string;
    inDrawer?: boolean;
    inPDPHero?: boolean;
    lookbookUIKey?: any;
}

const ProductBuy = ({
    price,
    compareAtPrice,
    sizes,
    currencyCode,
    inDrawer = false,
    inPDPHero = false,
    lookbookUIKey,
}: BuyProps) => {
    const { lang } = useParams();
    const { openUIKey, toggleUI, transitionUI } = useUI();
    const isWishlistFeatureEnabled = getFeatureFlag('wishlist');
    const {
        selectedSize,
        selectedVariant,
        isVariantInCheckout,
        variantLifecycleStatus,
        callbacks,
    } = useBuyContext();
    const { toggleItem, isSKUSaved } = useWishlist((state) => ({
        toggleItem: state.toggleItem,
        isSKUSaved: state.isSKUSaved,
    }));
    const { scrollContainerRef, hasReachedEnd, hasOverflowRight } =
        useHorizontalScrollObserver();
    const { isCreditShoppingActive } = useLoopReturns((state) => ({
        isCreditShoppingActive: state.isCreditShoppingActive,
    }));

    const priceFormatter = new Intl.NumberFormat(lang || 'en-AU', {
        style: 'currency',
        currency: currencyCode,
        currencyDisplay: 'narrowSymbol',
    });

    // If a product only has one size, autoselect it on mount
    useEffect(() => {
        if (sizes.length === 1) {
            callbacks.selectSize(sizes[0].size);
        }
    }, [sizes, callbacks]);

    let WishlistVector = BookmarkDisabled;
    if (selectedVariant) {
        WishlistVector = isSKUSaved(selectedVariant.sku as string)
            ? BookmarkFilled
            : BookmarkUnfilled;
    }

    // Apply gradient mask effect when scroll container has overflow
    // and the end of the scroll container has not been reached.
    const gradientMask =
        hasOverflowRight && !hasReachedEnd
            ? cn(s.gradientMask, s.active)
            : s.gradientMask;

    const inDrawerStyles = cn({
        [s.inDrawer]: inDrawer,
    });

    const button: CartButtonProps = {
        label: 'Select',
        state: 'default',
        disabled: false,
        onClick: () => {},
    };

    if (selectedVariant) {
        if (variantLifecycleStatus === VariantLifecycleStatus.ACTIVE) {
            button.label = 'Add';
            button.state = 'selected';
            button.onClick = callbacks.addToCart;
        }

        if (variantLifecycleStatus === VariantLifecycleStatus.NOTIFY) {
            button.label = 'Notify';
            button.state = 'alternative';
            button.onClick = () => {
                if (lookbookUIKey && openUIKey === lookbookUIKey) {
                    transitionUI(openUIKey, 'notify');
                } else {
                    toggleUI('notify');
                }
            };
        }

        if (variantLifecycleStatus === VariantLifecycleStatus.SOLD_OUT) {
            button.label = 'Sold out';
            button.state = 'soldOut';
            button.disabled = true;
            button.onClick = () => {};
        }
    }

    if (isVariantInCheckout) {
        button.label = 'Added';
        button.state = 'added';
        if (isCreditShoppingActive) {
            button.disabled = true;
        }
    }

    const toggleWishlistItem = () => {
        if (!selectedVariant) return;
        toggleItem({
            sku: selectedVariant.sku as string,
            handle: selectedVariant.product.handle as string,
        });
        if (isSKUSaved(selectedVariant.sku as string)) toggleUI('wishlist');
    };

    return (
        <div className={cn(s.root, inDrawerStyles)}>
            <div
                className={cn(
                    s.priceContainer,
                    s.spacer,
                    'mmds-component-one-detail'
                )}
            >
                {compareAtPrice && (
                    <div className={s.compareAtPrice}>
                        {priceFormatter.format(compareAtPrice)} {currencyCode}
                    </div>
                )}
                {price && (
                    <div
                        className={cn(s.price, {
                            [s.blur]: inPDPHero,
                            [s.spaceBetween]: !inPDPHero,
                        })}
                    >
                        {priceFormatter.format(price)} {currencyCode}
                    </div>
                )}
            </div>
            <div className={cn(s.sizeContainer, s.cluster)}>
                <div
                    className={cn(s.scrollContainer, s.spacer)}
                    ref={scrollContainerRef}
                >
                    {sizes.map(({ size, availability }) => {
                        let state: SizeState = 'default';
                        if (availability < 1) state = 'outOfStock';
                        if (selectedSize === size) {
                            if (availability) state = 'selected';
                            if (availability < 1) state = 'outOfStockSelected';
                        }
                        const hasLongLabel = size.length > 3;
                        return (
                            <SizeSelector
                                key={size}
                                label={size}
                                state={state}
                                hasLongLabel={hasLongLabel}
                                onClick={() => callbacks.selectSize(size)}
                            />
                        );
                    })}
                </div>
                <div className={cn(gradientMask)}>
                    {isWishlistFeatureEnabled && (
                        <div
                            className={cn(s.wishlist, {
                                [s.disabled]: !selectedVariant,
                            })}
                            onClick={toggleWishlistItem}
                            title={
                                selectedVariant
                                    ? 'Add to wishlist'
                                    : 'Select a size to add to wishlist'
                            }
                        >
                            <WishlistVector />
                        </div>
                    )}
                    <CartButton className={s.cartButton} {...button} />
                </div>
            </div>
        </div>
    );
};

export default ProductBuy;
