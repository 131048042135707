import { create } from 'zustand';
import { Product } from 'shopify-storefront-api-typings';

interface SelectedProductState {
    selectedProduct: Product | null;
    setSelectedProduct: (product: Product) => void;
}

export const useSelectedProductStore = create<SelectedProductState>((set) => ({
    selectedProduct: null,
    setSelectedProduct: (product) => set({ selectedProduct: product }),
}));
