'use client';

import React, { useState } from 'react';
import {
    Drawer,
    DrawerPanel,
    DrawerHeader,
    DrawerBody,
    DrawerFooter,
} from '@app/components/ui/drawer/Drawer';
import { Button, Checkbox, Input } from 'mmds';
import useUI from '@app/components/ui/state';
import { useParams } from 'next/navigation';
import { useBuyContext } from '@app/[lang]/products/context';
import useDrawerDirection from '@app/lib/hooks/use-drawer-direction';
import { hmacSignature } from '@arkadecx/arkade-cloud-functions-auth';
import cn from 'clsx';
import s from './product-notify-drawer.module.css';

const ProductNotifyDrawer = () => {
    const { lang } = useParams();
    const { selectedVariant } = useBuyContext();
    const [name, setName] = useState<string | null>(null);
    const [email, setEmail] = useState<string | null>(null);
    const [optIn, setOptIn] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [message, setMessage] = useState(null);
    const { openUIKey, toggleUI } = useUI();
    const isDrawerOpen = openUIKey === 'notify';
    const drawerDirection = useDrawerDirection();
    const toggleDrawer = () => {
        toggleUI(isDrawerOpen ? null : 'notify');
    };
    if (!selectedVariant) return null;

    const onSubmit = async () => {
        if (email && name) {
            setLoading(true);
            const now = Date.now();
            const route = '/api/notify';
            const body = JSON.stringify({
                name,
                email,
                variantId: selectedVariant.id,
                lang,
                optIn,
            });
            const signature = await hmacSignature(
                process.env.NEXT_PUBLIC_API_TOKEN,
                now,
                route,
                body
            );
            fetch('/api/notify', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-authentication': `${now}:${signature}`,
                },
                body,
            })
                .then((response) => response.json())
                .then((data) => {
                    setMessage(data.message);
                })
                .catch((error) => {
                    setMessage(error.message);
                })
                .finally(() => {
                    setName(null);
                    setEmail(null);
                    setLoading(false);
                });
        }
    };

    const onCancel = () => {
        setMessage(null);
        toggleDrawer();
    };

    return (
        <Drawer open={isDrawerOpen} onOpenChange={toggleDrawer}>
            <DrawerPanel inFrom={drawerDirection} variant="cart-nav">
                <DrawerHeader
                    closeButtonVariant="text"
                    className={s.drawerHeader}
                >
                    <div className={s.header}>
                        <p className="mmds-component-one-detail">
                            {selectedVariant.product.title}
                        </p>
                    </div>
                </DrawerHeader>
                <DrawerBody className={s.drawerBody}>
                    <hr className={s.divider} />
                    {loading && (
                        <div
                            className={cn(s.text, 'mmds-component-one-detail')}
                        >
                            Sending...
                        </div>
                    )}
                    {message && (
                        <div
                            className={cn(s.text, 'mmds-component-one-detail')}
                        >
                            <p>{message}</p>
                        </div>
                    )}
                    {!message && !loading && (
                        <>
                            <p className={cn('mmds-copy-two', s.text)}>
                                Your size is currently sold out, complete the
                                form and we'll notify you when it's back in
                                stock.
                            </p>
                            <fieldset className={cn(s.fieldSet, s.stack)}>
                                <div className={s.field}>
                                    <Input
                                        labelText="Name"
                                        onChange={(value) => setName(value)}
                                        type="text"
                                        id="name"
                                        name="name"
                                        placeholder="Your name..."
                                        required
                                    />
                                </div>
                                <div className={s.field}>
                                    <Input
                                        labelText="Email"
                                        onChange={(value) => setEmail(value)}
                                        type="email"
                                        id="email"
                                        name="email"
                                        placeholder="Your email..."
                                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                                        required
                                    />
                                </div>
                                <div className={cn(s.field, s.signupField)}>
                                    <Checkbox
                                        label="Sign up to our newsletter"
                                        id="newsletter_opt_in"
                                        checked={optIn}
                                        onCheckedChange={() => setOptIn(!optIn)}
                                    />
                                </div>
                            </fieldset>
                        </>
                    )}
                </DrawerBody>
                <DrawerFooter>
                    <div className={s.footer}>
                        <Button
                            variant="ghost"
                            onClick={onCancel}
                            label="Cancel"
                        />
                        <Button
                            className={s.button}
                            onClick={onSubmit}
                            label="Submit"
                            disabled={!name || !email}
                        />
                    </div>
                </DrawerFooter>
            </DrawerPanel>
        </Drawer>
    );
};

export default ProductNotifyDrawer;
